var primaryNavigation = function() {

	this.openPrimaryNav = false;
	var navigation = document.querySelector('.primary-navigation');
	var toggle = document.querySelector('.primary-navigation__toggle');
	var _this = this;
	var scrollY = document.documentElement.scrollTop || window.scrollY;

	this.init = function() {
		bindEvents();
	}

	function bindEvents() {
		toggle.addEventListener('click', function() {
			if (_this.openPrimaryNav || $('.primary-navigation').hasClass('open-configurator')){
				_this.close();
			}else {
				_this.open();
			}
		});

		document.body.addEventListener('touchmove', function(event) {
			if (_this.openPrimaryNav) event.preventDefault();
		});
	}

	this.open = function() {
		navigation.classList.add('open');
		document.body.classList.add('open-navigation');
		this.openPrimaryNav = true;
	}

	this.close = function() {
		$('.configurator').removeClass('open');
		navigation.classList.remove('open');
		navigation.classList.remove('open-configurator');
		document.body.classList.remove('open-navigation');
		if($('.configurator > iframe') != undefined) {
			$('.configurator > iframe').attr('src', '');
		}
		this.openPrimaryNav = false;
	}
}

module.exports = primaryNavigation;