var Slider = function () {
	var slider = 'default';
	var sliderWrapper = null;
	var sliderList = null;
	var slides = null;
	var slidePositions = [];
	var orgLength = null;
	var config = {
		cloneOffset: 4,
		touchThreshold: 50
	};
	var current = null;
	var prev = null;
	var next = null;
	var pagination = null;
	var pages = null;
	var buttons = null;
	var touchMovement = 0;
	var touchStartX = 0;
	var slideMovement = null;
	var animating = false;
	let buttonPrev = null;
	let buttonNext = null;
	let start = null;

	var uuid = null;
	this.init = function (element, options) {
		uuid = Math.random();
		slider = element;
		sliderWrapper = slider.querySelector('.slider-wrapper');
		sliderList = slider.querySelector('.slider-list');
		slides = slider.querySelectorAll('.slider-item');
		orgLength = slides.length;
		pagination = slider.querySelector('.slider-pagination');
		if (pagination) pages = pagination.querySelectorAll('li');
		buttonPrev = slider.querySelector('.slider-button-prev');
		buttonNext = slider.querySelector('.slider-button-next');
		current = config.cloneOffset;
		start = config.cloneOffset;
		buildDom();
		setTimeout(reset, 10);
		window.addEventListener('load', reset);
		bindEvents();
	}

	function bindEvents() {
		window.addEventListener('resize', function () {
			window.requestAnimationFrame(reset);
		});

		var timing = 130;
		var startLoop = false;

		function loop() {
			if(!startLoop) return;
			if(startLoop) {
				direction = 'prev';
				setSlide(getPrev(), 'prev');
				countHover++
				if (countHover > 60) {
					$('.js-slider, .js-navigationslider').addClass('skip');
					timing = 100;
				} else if (countHover > 35) {
					$('.js-slider, .js-navigationslider').addClass('fast');
					animating = false;
					slider.classList.remove('animating');
					timing = 200;
				} 
				window.setTimeout(loop, timing);
			}	
		}
		var infiniteLoop;
		var countHover = 0;
		if (buttonPrev) {
			buttonPrev.addEventListener('click', function () {
				direction = 'prev';
				setSlide(getPrev(), 'prev');
			}); 
			buttonPrev.addEventListener('mouseenter', function () {
				startLoop = true;
				window.setTimeout(loop, timing);
			});
			buttonPrev.addEventListener('mouseleave', function () {
			    startLoop = false;
			    timing = 130;
			    countHover = 0;
			    $('.js-slider, .js-navigationslider').removeClass('fast skip');
			});
		};

		var nexttiming = 130;
		var nextstartLoop = false;
		function loopNext() {
			if(!nextstartLoop) return;
			if(nextstartLoop) {
				direction = 'next';
				setSlide(getNext(), 'next');
				countHover++

				if (countHover > 60) {
					$('.js-slider, .js-navigationslider').addClass('skip');
					nexttiming = 100;
				} else if (countHover > 35) {
					$('.js-slider, .js-navigationslider').addClass('fast');
					animating = false;
					slider.classList.remove('animating');
					nexttiming = 200;
				} 
				window.setTimeout(loopNext, nexttiming);
			}	
		}

		if (buttonNext) {
			buttonNext.addEventListener('click', function () {
				direction = 'next';
				setSlide(getNext(), 'next');
			}); 
			buttonNext.addEventListener('mouseenter', function () {
				nextstartLoop = true;
				window.setTimeout(loopNext, nexttiming);
			});
			buttonNext.addEventListener('mouseleave', function () {
			    nextstartLoop = false;
			    nexttiming = 130;
			    countHover = 0;
			    $('.js-slider, .js-navigationslider').removeClass('fast skip');
			});
		};


		if (pagination) {
			for (var i = 0; i < pages.length; i++) {
				pages[i].addEventListener('click', function (el) {
					var nextIdx = parseInt(this.getAttribute('data-idx')) + config.cloneOffset;
					setSlide(nextIdx);
				});
			}
		}

		sliderWrapper.addEventListener('touchstart', touchStart, false);
		sliderWrapper.addEventListener('touchmove', touchMove, false);
		sliderWrapper.addEventListener('touchend', touchEnd, false);

		sliderList.addEventListener(transitionEndEvent, checkPosition, false);
	}

	function buildDom() {
		// add clones
		for (var i = 1; i <= config.cloneOffset; i++) {
			var preClone = slides[slides.length - i].cloneNode(true);
			var postClone = slides[i - 1].cloneNode(true);

			sliderList.insertBefore(preClone, sliderList.firstChild);
			sliderList.appendChild(postClone);
		}

		// new slides nodelist
		slides = slider.querySelectorAll('.slider-item');

		// prevent bubbling
		for (var i=0; i < slides.length; i++) {
		    slides[i].addEventListener(transitionEndEvent, function(event) {
				event.stopPropagation();
			})
		}
	}

	function reset() {
		calculatePositions();
		setSlide(current);
		resetSliderList();
	}

	this.start = function() {
		setSlide(start);
		resetSliderList();
	}

	function calculatePositions() {
		for (var i = 0; i < slides.length; i++) {
			slidePositions[i] = slides[i].offsetLeft + (slides[i].clientWidth / 2);
		}
	}

	function setSlide(idx, direction) {
		if (!animating) {
			current = idx;
			prev = getPrev();
			next = getNext();

			resetClasses();
			setClasses();
			moveSlide(direction);
			if (pagination) setPagination();
		}
	}

	function getPrev() {
		return (current > config.cloneOffset) ? current - 1 : orgLength + config.cloneOffset - 1;
	}

	function getNext() {
		return (current < orgLength + config.cloneOffset - 1) ? current + 1 : config.cloneOffset;
	}

	function resetClasses() {
		for (var i = 0; i < slides.length; i++) {
			slides[i].classList.remove('prev');
			slides[i].classList.remove('active');
			slides[i].classList.remove('next');
		}
	}

	function setClasses() {
		for (var i = 0; i < slides.length; i++) {
			if (i == prev || i == prev - orgLength) slides[i].classList.add('prev');
			else if (i == current || i == current + orgLength || i == current - orgLength) slides[i].classList.add('active');
			else if (i == next || i == next + orgLength) slides[i].classList.add('next');
		}
	}

	function setPagination() {
		for (var i = 0; i < pages.length; i++) {
			if (i == current - config.cloneOffset) pages[i].classList.add('active');
			else pages[i].classList.remove('active');
		}
	}

	function moveSlide(direction) {
		var moveTo;
		animating = true;
		slider.classList.remove('prev');
		slider.classList.remove('next');
		$('.js-slider, .js-navigationslider').removeClass('sliding');
		if (direction) slider.classList.add(direction);

		if (direction == 'next' && current === config.cloneOffset) {
			moveTo = current + orgLength;
			sliderList.style.transform = 'translateX(' + -slidePositions[moveTo] + 'px)';
			$('.js-slider, .js-navigationslider').addClass('sliding');
		} else 
		if (direction == 'prev' && current === slides.length - config.cloneOffset - 1) {
			moveTo = current - orgLength;
			sliderList.style.transform = 'translateX(' + -slidePositions[moveTo] + 'px)';
		} else {
			sliderList.style.transform = 'translateX(' + -slidePositions[current] + 'px)';
		}
	}

	function checkPosition() {
		if (current === config.cloneOffset || current === slides.length - config.cloneOffset - 1) resetPosition();
		else resetSliderList();
	}

	function resetPosition() {
		sliderList.classList.add('resetting');
		sliderList.style.transform = sliderList.style.transform = 'translateX(' + -slidePositions[current] + 'px)';
		setTimeout(function () {
			resetSliderList();
		}, 1);
	}

	function resetSliderList() {
		animating = false;
		slider.classList.remove('animating');
		sliderList.classList.remove('resetting');
	}

	// Touch functions
	function touchStart(event) {
		if (!animating) {
			touchStartX = event.touches[0].pageX;
			sliderList.classList.add('touching');
		}
	}

	function touchMove(event) {
		if (!animating) {
			touchMovement = event.touches[0].pageX - touchStartX;
			slideMovement = -slidePositions[current] + touchMovement;

			sliderList.style.transform = 'translateX(' + slideMovement + 'px' + ')';
			sliderList.style.webkitTransform = 'translateX(' + slideMovement + 'px' + ')';
		}
	}

	function touchEnd(event) {
		var nextIdx = (touchMovement < 0) ? getNext() : getPrev();
		var direction = (touchMovement < 0) ? 'next' : 'prev';

		if (Math.abs(touchMovement) > config.touchThreshold) setSlide(nextIdx, direction);
		else moveSlide();

		sliderList.classList.remove('touching');
		touchMovement = 0;
	}

	// Function from David Walsh: http://davidwalsh.name/css-animation-callback
	function whichTransitionEvent() {
		var t,
			el = document.createElement("fakeelement");

		var transitions = {
			"transition": "transitionend",
			"OTransition": "oTransitionEnd",
			"MozTransition": "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		}

		for (t in transitions) {
			if (el.style[t] !== undefined) {
				return transitions[t];
			}
		}
	}
	var transitionEndEvent = whichTransitionEvent();

}

module.exports = Slider;