
function scrollReveal(selector) {
	var elements = document.querySelectorAll(selector);
	var offset;
	var keepVisible = false;
	var threshold = 50;

	setTimeout(checkPosition, 1000);
	bindEvents();

	function bindEvents() {
		window.addEventListener('scroll', function() {
			requestAnimationFrame(checkPosition);
		})
	}

	function checkPosition() {
		Array.prototype.forEach.call(elements, function(el){
			offset = el.getBoundingClientRect();
			keepVisible = (el.getAttribute('data-keep-visible')) ? true : false;

			if (offset.top >= 0 + threshold && offset.top < window.innerHeight - threshold ||
				offset.top + offset.height > 0 + threshold && offset.top + offset.height <= window.innerHeight - threshold ||
				offset.top < 0 + threshold && offset.top + offset.height > window.innerHeight - threshold ){
				el.classList.add('visible');
				var event = new CustomEvent('visible', { 'selectir': selector });
			} else {
				if (!keepVisible)el.classList.remove('visible');
			}
		});
	}
}

module.exports = scrollReveal;