// 'use strict';
var attachFastClick = require('fastclick');
attachFastClick(document.body);

var $ = require('jquery');
window.$ = window.jQuery = $;

var objectFitImages = require('object-fit-images');
objectFitImages();

var sliderImageElements = document.querySelectorAll('.js-product-images');
Array.prototype.forEach.call(sliderImageElements, function (element, index) {
	let $element = $('.js-product-images');
    var currentIndex = 0;
    var hoverToIndex = 0;
    var newIndex = 0;
    var $activeItem = $element.find('.slider__item--active');
    var sliderCount = $element.find('.slider__item').length;
    
    if (sliderCount > 1) {
	    
    	function navigateToIndex(newIndex){
            newIndex = (newIndex + sliderCount) % (sliderCount);
            $('.slider__item--active').removeClass('slider__item--active');
            $activeItem = $element.find('.slider__item').eq(newIndex);
            $activeItem.addClass('slider__item--active');
            currentIndex = newIndex;
        };

        navigateToIndex(0);

        $element.find('.slider-button-prev').on('click', function(event){
            event.stopPropagation();
            event.preventDefault();
            navigateToIndex(currentIndex - 1);
            hoverToIndex = (currentIndex - 1 + sliderCount) % (sliderCount);
            return false;
        });

        $element.find('.slider-button-next').on('click', function(event){
            event.stopPropagation();
            event.preventDefault();
            navigateToIndex(currentIndex + 1);
            hoverToIndex = (currentIndex + 1 + sliderCount) % (sliderCount);
            return false;
        }); 

    }
});

// Contact map
// var openMap = false;
// var map;
// var marker;
// var infowindow;

// window.initMap = function () {
// 	var mapElement = document.getElementById('contact-map');
// 	var lat = parseFloat(mapElement.dataset.lat);
// 	var lng = parseFloat(mapElement.dataset.lng);
// 	var zoom = parseInt(mapElement.dataset.zoom);

// 	var location = {
// 		lat: lat,
// 		lng: lng
// 	}

// 	map = new google.maps.Map(mapElement, {
// 		center: location,
// 		zoom: zoom,
// 		mapTypeControl: false

// 	});

// 	var contentString = document.querySelector('.infowindow-content').innerHTML;

// 	infowindow = new google.maps.InfoWindow({
// 		content: contentString,
// 		pixelOffset: new google.maps.Size(-38, -0)
// 	});


// 	var markerImage = {
// 		url: '/assets/images/marker.png',
// 		size: new google.maps.Size(152, 152),
// 		scaledSize: new google.maps.Size(76, 76),
// 		origin: new google.maps.Point(0, 0),
// 		anchor: new google.maps.Point(38, 70)
// 	}

// 	marker = new google.maps.Marker({
// 		position: location,
// 		map: map,
// 		icon: markerImage,
// 		title: 'Brothers and Sons'
// 	});

// 	marker.addListener('click', function() {
// 		infowindow.open(map, marker);
// 	});
// }

$('.js-open-configurator').on('click', function(e) {
	e.preventDefault();
	var href = $(this).attr('href');
	var newUrl = href + '&customcss=/assets/styles/custom-config.css';
	$('.primary-navigation').addClass('open-configurator');
	$('.configurator').addClass('open');
	document.body.classList.add('open-configurator');
	$('.configurator > iframe').attr('src', newUrl);
}) ;

// function toggleMap() {
// 	if (openMap) hideMap();
// 	else showMap();
// }

// function hideMap() {
// 	$('.section-contact').removeClass('hide');
// 	$('.section-map').removeClass('active');
// 	$('.primary-navigation__map').removeClass('active');
// 	$('#fp-nav').addClass('show');
// 	openMap = false;
// }

// function showMap() {
// 	if (primaryNavigation.openPrimaryNav) primaryNavigation.close();
// 	$('.section-contact').addClass('hide');
// 	$('.section-map').addClass('active');
// 	$('.primary-navigation__map').addClass('active');
// 	$('#fp-nav').removeClass('show');
// 	openMap = true;
// }

// function goToMap() {
// 	var idx = parseInt($('.section-contact').data('section-id'));

// 	if ($(window).width() < 1024) {
// 		scrollTo('.section-contact__map');
// 	}
// 	else {
// 		if (hasFullPage && currentSection !== idx && !openMap) {
// 			$.fn.fullpage.moveTo(idx);
// 			window.setTimeout(showMap, 1000);
// 		}
// 		else if (hasFullPage && currentSection === idx && !openMap) {
// 			showMap();
// 		}
// 		else if (!hasFullPage && !openMap) {
// 			scrollTo('.section-contact__map');
// 			if ($(window).width() >= 1024) showMap();
// 		}
// 		else {
// 			hideMap();
// 		}
// 	}
// }

function checkLocationHash() {
	if (location.hash === '#location') goToMap();
}

function scrollTo(selector, duration, offset) {
	var duration = (duration) ? duration : 700;
	var scrollTop = (offset) ? $(selector).offset().top + offset : $(selector).offset().top;
	$('html, body').animate({
        scrollTop: scrollTop
    }, duration);
}
//scrollto
$('.js-scroll-to').each(function(event) {
	var $element = $(this);
	var target = $element.attr('href');

	$element.on('click', function(event) {
		event.preventDefault();
		scrollTo('body,html', target, 400);
	});
});

// $('.js-showMap').on('click', toggleMap);
// $('.section-map__toggle').on('click', showMap);
// $('.js-goToMap').on('click', goToMap);
// $('.primary-navigation__toggle').on('click', hideMap);

window.setTimeout(checkLocationHash, 1000);

// Primary navigation
var PrimaryNavigation = require('./primaryNavigation');
var primaryNavigation = new PrimaryNavigation();
var primaryNavigationElement = document.querySelector('.primary-navigation');
if (primaryNavigationElement) primaryNavigation.init();

// general Slider logic
var Slider = require('./Slider');

// nav product slider
var navSliderElement = document.querySelector('.js-navigationslider');
var navSlider = new Slider();
if (navSliderElement) {
	navSlider.init(navSliderElement);
}

var primaryNavicationToggle = document.querySelector('.primary-navigation__toggle');
if (primaryNavicationToggle) {
	primaryNavicationToggle.addEventListener('click', function () {
		navSlider.start();
	});
}

// Homepage FullPage slider
var hasFullPage = false;
var currentSection = null;

var fullPage = require('fullpage.js');
var options = {
	navigation: true,
	slidesNavigation: true,
	loopHorizontal: false,
	responsiveWidth: 1024,
	touchSensitivity: 10,
	afterRender: function () {
		hasFullPage = true;
		currentSection = 1;
		hideLoader();
		if ($(window).width() >= 1024) checkBrightness(this);
	},
	afterResize: function () {
		$.fn.fullpage.silentMoveTo(1, 0);
		$.fn.fullpage.reBuild();
		setTimeout(function () {
			$('body').scrollTop(0);
		}, 100);
		if ($(window).width() >= 1024) checkBrightness(this);
		else setBrightness('dark', 'dark');
	},
	onLeave: function (index, nextIndex, direction) {
		currentSection = nextIndex;
		if ($(window).width() >= 1024) checkBrightness($(this), nextIndex, 'section');
		// if (openMap) hideMap();
	},
	onSlideLeave: function (anchorLink, index, slideIndex, direction, nextSlideIndex) {
		if ($(window).width() >= 1024) checkBrightness($(this), nextSlideIndex, 'slide');
	}
};
if ($('#fullpage').length > 0) $('#fullpage').fullpage(options);

var productOptions = {
	navigation: false,
	slidesNavigation: false,
	loopHorizontal: false,
	responsiveWidth: 1024,
	touchSensitivity: 10,
	autoScrolling: false,
	fitToSection: false,
	afterRender: function () {
		hasFullPage = true;
		currentSection = 1;
		hideLoader();
		if ($(window).width() >= 1024) checkBrightness(this);
	},
	afterResize: function () {
		if ($(window).width() >= 1024) checkBrightness(this);
		else setBrightness('dark', 'dark');
	},
	onLeave: function (index, nextIndex, direction) {
		currentSection = nextIndex;
		if ($(window).width() >= 1024) checkBrightness($(this), nextIndex, 'section');
		// if (openMap) hideMap();
	},
	onSlideLeave: function (anchorLink, index, slideIndex, direction, nextSlideIndex) {
		if ($(window).width() >= 1024) checkBrightness($(this), nextSlideIndex, 'slide');
	}
};
if ($('#productFullpage').length > 0) $('#productFullpage').fullpage(productOptions);

// Brightness check for logo and slider navigation
function checkBrightness(elem, nextIndex, type) {
	var theme;
	var navTheme;

	if (type == 'section') {
		var nextSlide = elem.parent().children().get(nextIndex - 1);
		var $nextSlide = $(nextSlide);
		if ($nextSlide.hasClass('has-slides')) {
			theme = $nextSlide.find('.fp-slide.active').get(0).getAttribute('data-theme');
			navTheme = $nextSlide.find('.fp-slide.active').get(0).getAttribute('data-nav-theme');
		} else {
			theme = $nextSlide.data('theme');
			navTheme = $nextSlide.data('nav-theme');
		}
	} else if (type == 'slide') {
		var nextSlide = elem.parent().children().get(nextIndex);
		theme = nextSlide.getAttribute('data-theme');
		navTheme = nextSlide.getAttribute('data-nav-theme');
	} else {
		theme = elem.data('theme');
		navTheme = elem.data('nav-theme');
	}

	setBrightness(theme, navTheme);
}

function setBrightness(theme, navTheme) {
	$('.js-checkBrightness').removeClass('light dark').addClass(theme);
	$('#fp-nav').removeClass('light dark').addClass(navTheme);
}

function checkCustomBrightness() {
	if ($('.js-set-brightness').length > 0) {
		var theme = $('.js-set-brightness').data('theme');

		if ($(window).width() >= 1024) setBrightness(theme, null);
		else setBrightness('dark', null);
	}
}
checkCustomBrightness();

$(window).on('resize', checkCustomBrightness);

function hideLoader() {
	window.setTimeout(function() {
		$('.page-wrap').removeClass('loading');
		$('.site-logo').removeClass('loading');
		$('#fp-nav').addClass('show');
		$('.loader').addClass('hide');
	}, 1500);
}

// Custom section buttons
$('.js-nextSection').on('click', function () {
	$.fn.fullpage.moveSectionDown();
});

$('.js-firstSection').on('click', function () {
	$.fn.fullpage.moveTo(1, 0);
});

$('.js-scrollTop').on('click', function () {
	scrollTo('body');
});

// Animate elements when in viewport
var scrollReveal = require('./scrollReveal');
scrollReveal('.js-scroll-reveal');


// product slider
var sliderElements = document.querySelectorAll('.js-slider');
Array.prototype.forEach.call(sliderElements, function (element) {
	var productSlider = new Slider();
	productSlider.init(element);
});

// General loader
if ($('.js-loader')) hideLoader();

// Product header color picker
var colorSwitcherElements = document.querySelectorAll('.js-color-switcher');
Array.prototype.forEach.call(colorSwitcherElements, function (element, index) {

	var colorSwitcher = element;
	var colorSwitcherButtons = null;
	var colorSwitcherTargetElement = null;
	var colorSwitcherTargetGallery = null;
	var colorSwitcherTargetImg = null;


	if (colorSwitcher.querySelectorAll('button[data-label]').length > 0) init();

	function init() {
		colorSwitcherButtons = colorSwitcher.querySelectorAll('button[data-label]');
		colorSwitcherTargetElement = document.querySelector(colorSwitcher.dataset.target);
		colorSwitcherTargetGallery = colorSwitcherTargetElement.querySelector('.js-gallery');
		colorSwitcherTargetImg = colorSwitcherTargetElement.querySelector('img');

		Array.prototype.forEach.call(colorSwitcherButtons, function (element) {
			element.addEventListener('click', switchColor);
		});
	}

	function switchColor(event) {
		if (event.target.dataset.image) {
			colorSwitcherTargetImg.src = event.target.dataset.image;
			colorSwitcherTargetGallery.setAttribute('data-index', event.target.dataset.idx);
		}

		if (window.Modernizr.touchevents) {
			if (event.target.parentNode.classList.contains('active')) {
				event.target.parentNode.classList.remove('active');
			}
			else {
				resetClasses();
				event.target.parentNode.classList.add('active');
			}
		}

		if ($(window).width() < 1024) scrollTo('.color-switcher-list', 300, -120);
	}

	function resetClasses() {
		for (var i = 0; i < colorSwitcherButtons.length; i++) {
			colorSwitcherButtons[i].parentNode.classList.remove('active');
		}
	}
});

// Full width titles
// require('./bigtext');
// $('.js-bigtext').bigtext({
// 	maxfontsize: 400
// });
// $(window).on('resize', function () {
// 	$.fn.bigtext.apply($('.js-bigtext'));
// });

// Photoswipe gallery
var PhotoSwipe = require('photoswipe');
var PhotoSwipeUI_Default = require('../../../node_modules/photoswipe/dist/photoswipe-ui-default.min.js');
var galleryElements = document.querySelectorAll('.js-gallery');
Array.prototype.forEach.call(galleryElements, function (element, index) {
	var galleryDOM = document.querySelector('.pswp');
	var galleryIndex = (element.dataset.index) ? parseInt(element.dataset.index) : 0;
	var sources = (element.dataset.images.indexOf(',')) ? element.dataset.images.split(',') : [element.dataset.images];
	var widths = (element.dataset.widths.indexOf(',')) ? element.dataset.widths.split(',') : [element.dataset.widths];
	var heights = (element.dataset.heights.indexOf(',')) ? element.dataset.heights.split(',') : [element.dataset.heights];
	var zoomOpacity = (element.hasAttribute('data-zoom-opacity')) ? true : false;
	var items = [];
	var openBtn = element.querySelector('.js-open-gallery');

	for (var i=0; i < sources.length; i++) {
	    items[i] = {
			src: sources[i],
			w: parseInt(widths[i], 10),
			h: parseInt(heights[i], 10)
		}
	}
	var options = {
		index: galleryIndex,
		bgOpacity: 0.95,
		loop: true,
		history: false,
		closeEl:true,
		captionEl: true,
		barsSize: {top:60, bottom:'auto'},
		fullscreenEl: false,
		zoomEl: true,
		shareEl: false,
		counterEl: true,
		arrowEl: true,
		preloaderEl: true,
		showAnimationDuration: 300,
        hideAnimationDuration: 300,
		showHideOpacity: zoomOpacity,
		preload: [1, 1],
		preloaderEl: true,
		getThumbBoundsFn: function(index) {
			// See Options -> getThumbBoundsFn section of documentation for more info
			var thumbnail = openBtn, // find thumbnail
				pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
				rect = thumbnail.getBoundingClientRect();

			return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
		}
	};

	if (openBtn) {
		openBtn.addEventListener('click', function() {
			options.index = (element.dataset.index) ? parseInt(element.dataset.index) : 0;
			var gallery = new PhotoSwipe(galleryDOM, PhotoSwipeUI_Default, items, options);
			gallery.init();
		});
	}
});

//back to collection
$(function() {
	var url = window.location.href;
	var lastPartUrl = url.substr(url.lastIndexOf('?') + 1);
	if (lastPartUrl === "fromcollection") {
	   $(".product-header__subtitle").html($(".product-header__subtitle").html().replace("View", "Back to"));
	}
});

// Newsletter form
(function() {
	var formWrapper = document.querySelector('.js-newsletter');
	var form = null;
	var email = null;

	if (formWrapper) init();

	function init() {
		form = formWrapper.querySelector('form');
		email = document.querySelector('input[type="email"]');
		bindEvents();
	}

	function bindEvents() {

		form.addEventListener('submit', function(event) {
			event.preventDefault();

			if (!validate(email.value)) {
				showError();
			}
			else {
				hideError();
				sendForm();
			}
		});

		email.addEventListener('focus', hideError);
	}

	function sendForm() {
		$.post('/', $(form).serialize())
			.done(function (data) {
				// Success
				if (data.success && data.message != 'Already subscribed') {
					showMessage('.success');
					email.value = '';
					email.blur();
				}
				// Already subscribed
				else if (data.success && data.message == 'Already subscribed' )  {
					showMessage('.subscribed');
					email.value = '';
					email.blur();
				}
				// Error
				else {
					showMessage('.error');
				}
			})
			.fail( function(err) {
				showMessage('error', err.message);
			});
	}

	function validate(field) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(field);
	}

	function showMessage(className, message) {
		var notification = formWrapper.querySelector(className);
		if (message) {
			notification.innerText = message;
		}
		notification.classList.add('show');

		setTimeout(function() {
			notification.classList.remove('show');
		}, 7000);
	}

	function showError(errorMessage) {
		email.classList.add('invalid');
	}

	function hideError() {
		email.classList.remove('invalid');
	}

})();

(function () {
	var $pricelists = $('.price-list').find('li');
	$('.js-select-country').on('change blur', function(event) {
		var country = event.target.value;
		filterCountries(country);
	});

	function filterCountries(country) {
		$pricelists.each(function() {
			if (this.dataset.country == country) $(this).show();
			else if (country == 'all') $(this).show();
			else $(this).hide();
		});
	}
})()


